<script>
export default {
  name: "nav",
  data() {
    return {
      ms_akses: [],
      AllSideBarItem: [
        {
          _name: "CSidebarNavItem",
          name: this.$t("menu.dashboard"),
          to: "/dashboard",
          icon: "cil-speedometer",
        },
        {
          _name: "CSidebarNavItem",
          name: "Dashboard Puskesmas",
          to: "/dashboard-puskesmas",
          icon: "cil-speedometer",
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Master",
          route: "/master",
          icon: "cil-star",
          items: [
            {
              name: "Asuransi",
              to: "/master/asuransi",
            },
            {
              name: "Alergi",
              to: "/master/alergi",
            },
            {
              name: "Diagnosa",
              to: "/master/diagnosa",
            },
            // {
            //   name: "Diagnosa Gizi",
            //   to: "/master/diagnosa_gizi",
            // },
            // {
            //   name: "Diet",
            //   to: "/master/diet",
            // },
            {
              name: "Barang",
              to: "/master/barang",
            },
            // {
            //   name: "Etnis",
            //   to: "/master/etnis",
            // },
            // {
            //   name: "Golongan Barang",
            //   to: "/master/golongan_barang",
            // },
            {
              name: "Golongan Darah",
              to: "/master/golongan_darah",
            },
            {
              name: "Gudang",
              to: "/master/gudang",
            },
            // {
            //   name: "Imunisasi",
            //   to: "/master/imunisasi",
            // },
            {
              name: "Jadwal",
              to: "/master/jadwal",
            },
            // {
            //   name: "Jenis Alergi",
            //   to: "/master/jenis_alergi",
            // },
            {
              name: "Jenis Barang",
              to: "/master/jenis_barang",
            },
            {
              name: "Jenis Layanan",
              to: "/master/jenis_layanan",
            },
            {
              name: "Jenis Tenaga Medis",
              to: "/master/jenis_tenaga_medis",
            },
            {
              name: "Kelas",
              to: "/master/kelas",
            },
            {
              name: "Kelas Terapi",
              to: "/master/kelas_terapi",
            },
            // {
            //   name: "Kelompok Pangan",
            //   to: "/master/kelompok_pangan",
            // },
            {
              name: "Komponen Tarif",
              to: "/master/komponen_tarif",
            },
            {
              name: "Komposisi Barang",
              to: "/master/komposisi_barang",
            },
            {
              name: "Kualifikasi",
              to: "/master/kualifikasi",
            },
            // {
            //   name: "Layanan",
            //   to: "/master/layanan",
            // },
            {
              name: "Loket",
              to: "/master/loket",
            },
            {
              name: "MCU",
              to: "/master/mcu",
            },
            // {
            //   name: "Pangan",
            //   to: "/master/pangan",
            // },
            {
              name: "Produsen",
              to: "/master/produsen",
            },
            {
              name: "Satuan Barang",
              to: "/master/satuan_barang",
            },
            {
              name: "Specialist",
              to: "/master/specialist",
            },
            {
              name: "Status Pulang",
              to: "/master/status_pulang",
            },
            {
              name: "Sumber Dana",
              to: "/master/sumber_dana",
            },
            {
              name: "Supplier",
              to: "/master/supplier",
            },
            // {
            //   name: "Pendidikan",
            //   to: "/master/pendidikan",
            // },
            {
              name: "Poli",
              to: "/master/poli",
            },
            {
              name: "Puskesmas",
              to: "/master/puskesmas",
            },
            {
              name: "Tenaga Medis",
              to: "/master/tenaga_medis",
            },
            // {
            //   name: "Tipe Pangan",
            //   to: "/master/tipe_pangan",
            // },
            {
              name: "Tindakan",
              to: "/master/tindakan",
            },
            {
              name: "User",
              to: "/master/user",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Apotek",
          route: "/apotek",
          icon: "cil-medical-cross",
          items: [
            {
              name: "Resep",
              to: "/apotek/resep",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Bed",
          route: "/bed",
          icon: "cil-bed",
          items: [
            {
              name: "Ruang",
              to: "/bed/ms_ruang",
            },
            {
              name: "Kamar",
              to: "/bed/ms_kamar",
            },
            {
              name: "Master Bed",
              to: "/bed/ms_bed",
            },
            {
              name: "Monitoring",
              to: "/bed/monitoring",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Antrian",
          route: "/antrian",
          icon: "cil-library",
          items: [
            {
              name: "Antrian Loket",
              to: "/antrian/antrian_loket",
            },
            {
              name: "Antrian Poli",
              to: "/antrian/antrian_poli",
            },
            // {
            //   name: "Antrian Farmasi",
            //   to: "/antrian/antrian_farmasi",
            // },
            {
              name: "Display APM",
              to: "/display_apm",
              target: "_blank",
            },
            {
              name: "Display Layar Poli",
              to: "/display_list_poli",
              target: "_blank",
            },
            {
              name: "Display Layar Pendaftaran",
              to: "/display_layar_loket",
              target: "_blank",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Pasien",
          route: "/pasien",
          icon: "cil-people",
          items: [
            {
              name: "Rekam Medis",
              to: "/pasien/rekam_medis",
            },
            {
              name: "Pasien Meninggal",
              to: "/pasien/pasien_meninggal",
            },
            // {
            //   name: "Kegiatan Kelompok",
            //   to: "/pasien/kegiatan_kelompok",
            // },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Registrasi",
          route: "/registrasi",
          icon: "cil-list",
          items: [
            {
              name: "Pendaftaran",
              to: "/registrasi/pendaftaran",
            },
            {
              name: "Kunjungan",
              to: "/registrasi/kunjungan",
            },
            // {
            //   name: "Penimbangan Balita",
            //   to: "/registrasi/penimbangan_balita",
            // },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Gudang",
          route: "/gudang",
          icon: "cil-layers",
          items: [
            {
              name: "Stock",
              to: "/gudang/stock/" + this.$store.state.puskesmas_id,
            },
            {
              name: "Pembelian",
              to: "/gudang/pembelian",
            },
            {
              name: "Mutasi",
              to: "/gudang/mutasi",
            },
            {
              name: "Stock Opname",
              to: "/gudang/stock_opname",
            },
            {
              name: "Adjutsment",
              to: "/gudang/adjutsment",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Laboratorium",
          route: "/laboratorium",
          icon: "cil-layers",
          items: [
            {
              name: "Satuan Laboratorium",
              to: "/laboratorium/satuan_lab",
            },
            {
              name: "Sampel Lab",
              to: "/laboratorium/sampel_lab",
            },
            {
              name: "Nilai Rujukan Lab",
              to: "/laboratorium/nilai_rujukan_lab",
            },
            {
              name: "Tindakan Lab",
              to: "/laboratorium/tindakan_lab",
            },
            {
              name: "Paket Lab",
              to: "/laboratorium/paket_lab",
            },
            {
              name: "Pemeriksaan Lab",
              to: "/laboratorium/pemeriksaan_lab",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Kasir",
          route: "/kasir",
          icon: "cil-layers",
          items: [
            {
              name: "Pembayaran",
              to: "/kasir/pembayaran",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Laporan Harian",
          route: "/laporan",
          icon: "cil-layers",
          items: [
            {
              name: "Kunjungan Pasien",
              to: "/laporan/kunjungan_pasien",
            },
            {
              name: "Kunjungan Pasien BPJS",
              to: "/laporan/kunjungan_pasien_bpjs",
            },
            {
              name: "Penyakit",
              to: "/laporan/penyakit",
            },
            // {
            //   name: "Laporan Pemeriksaan Medis",
            //   to: "/laporan/pemeriksaan_medis",
            // },
            // {
            //   name: "Laporan Pelayanan Pasien",
            //   to: "/laporan/pelayanan_pasien",
            // },
            // {
            //   name: "Laporan Pelayanan Resep",
            //   to: "/laporan/pelayanan_resep",
            // },
            // {
            //   name: "Laporan Pemeriksaan Lab",
            //   to: "/laporan/pemeriksaan_lab",
            // },
            {
              name: "Laporan Pengeluaran Obat",
              to: "/laporan/pelayanan_obat",
            },
            {
              name: "Laporan Tindakan Tenaga Medis",
              to: "/laporan/tindakan_tenaga_medis",
            },
            // {
            //   name: "Laporan Pendapatan Tindakan",
            //   to: "/laporan/pendapatan_tindakan",
            // },
            {
              name: "Laporan Rujuk Eksternal",
              to: "/laporan/rujuk_eksternal",
            },
            {
              name: "Laporan Rujuk Internal",
              to: "/laporan/rujuk_internal",
            },
            // {
            //   name: "Laporan Pelayanan Ruang",
            //   to: "/laporan/pelayanan_ruang",
            // },
            // {
            //   name: "Laporan Kinerja Puskesmas",
            //   to: "/laporan/kinerja_puskesmas",
            // },
            // {
            //   name: "Laporan PKPR",
            //   to: "/laporan/pkpr",
            // },
            {
              name: "Laporan Kunjungan PTM",
              to: "/laporan/kunjungan_ptm",
            },
            {
              name: "Laporan Pendapatan Kasir",
              to: "/laporan/pendapatan_kasir",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Laporan Bulanan",
          route: "/laporan_bulanan",
          icon: "cil-layers",
          items: [
            {
              name: "Laporan SP3 LB1",
              to: "/laporan_bulanan/sp3lb1",
            },
            {
              name: "Laporan UKP-4",
              to: "/laporan_bulanan/ukp4",
            },
            {
              name: "Laporan UKP-5",
              to: "/laporan_bulanan/ukp5",
            },
            {
              name: "Kunjungan Ibu Hamil",
              to: "/laporan_bulanan/kunjungan_ibu_hamil",
            },
            {
              name: "Kohort KIA",
              to: "/laporan_bulanan/kohort_kia",
            },
            // {
            //   name: "Infeksi Menular Seksual",
            //   to: "/laporan_bulanan/infeksi_menular_seksual",
            // },
            // {
            //   name: "Konseling Hiv",
            //   to: "/laporan_bulanan/konseling_hiv",
            // },
            // {
            //   name: "Rekapitulasi MTBS MTBM",
            //   to: "/laporan_bulanan/rekapitulasi_mtbs_mtbm",
            // },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Grafik",
          route: "/grafik",
          icon: "cil-layers",
          items: [
            // {
            //   name: "Pemeriksaan Tenaga Medis",
            //   to: "/grafik/pemeriksaan_tenaga_medis",
            // },
            // {
            //   name: "Pendapatan Harian",
            //   to: "/grafik/pendapatan_harian",
            // },
            {
              name: "Statistik Pasien",
              to: "/grafik/statistik_pasien",
            },
            {
              name: "Grafik Tindakan",
              to: "/grafik/tindakan",
            },
            // {
            //   name: "Grafik Transaksi",
            //   to: "/grafik/transaksi",
            // },
            // {
            //   name: "Grafik Pembayaran",
            //   to: "/grafik/pembayaran",
            // },
            {
              name: "Grafik Penyakit",
              to: "/grafik/penyakit",
            },
            {
              name: "Grafik Stock Opname",
              to: "/grafik/stock_opname",
            },
            {
              name: "Statistik Kunjungan",
              to: "/grafik/statistik_kunjungan",
            },
            // {
            //   name: "Statistik Pembelian",
            //   to: "/grafik/statistik_pembelian",
            // },
            // {
            //   name: "Statistik Prolanis",
            //   to: "/grafik/statistik_prolanis",
            // },
            {
              name: "Kunjungan",
              to: "/grafik/kunjungan",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Content Management",
          route: "/cms",
          icon: "cil-settings",
          items: [
            {
              name: "Master Akses",
              to: "/cms/ms_akses",
            },
            {
              name: "Role Akses",
              to: "/cms/role_akses",
            },
            {
              name: "Master Role",
              to: "/cms/ms_role",
            },
          ],
        },
        {
          _name: "CSidebarNavItem",
          name: "I-Care",
          to: "/icare",
          icon: "cil-square",
        },
      ],
    };
  },
  // computed: {
  //   sidebarItems() {
  //     return [
  //       {
  //         _name: "CSidebarNav",
  //         _children: [
  //           {
  //             _name: "CSidebarNavItem",
  //             name: this.$t("menu.dashboard"),
  //             to: "/dashboard",
  //             icon: "cil-speedometer",
  //           },
  //           {
  //             _name: "CSidebarNavDropdown",
  //             name: "Master",
  //             route: "/master",
  //             icon: "cil-star",
  //             items: [
  //               {
  //                 name: "Asuransi",
  //                 to: "/master/asuransi",
  //               },
  //               {
  //                 name: "Alergi",
  //                 to: "/master/alergi",
  //               },
  //               {
  //                 name: "MsDiagnosa",
  //                 to: "/master/diagnosa",
  //               },
  //               {
  //                 name: "Diagnosa Gizi",
  //                 to: "/master/diagnosa_gizi",
  //               },
  //               {
  //                 name: "Diet",
  //                 to: "/master/diet",
  //               },
  //               {
  //                 name: "Barang",
  //                 to: "/master/barang",
  //               },
  //               {
  //                 name: "Etnis",
  //                 to: "/master/etnis",
  //               },
  //               // {
  //               //   name: "Golongan Barang",
  //               //   to: "/master/golongan_barang",
  //               // },
  //               {
  //                 name: "Golongan Darah",
  //                 to: "/master/golongan_darah",
  //               },
  //               {
  //                 name: "Gudang",
  //                 to: "/master/gudang",
  //               },
  //               {
  //                 name: "Jadwal",
  //                 to: "/master/jadwal",
  //               },
  //               // {
  //               //   name: "Jenis Alergi",
  //               //   to: "/master/jenis_alergi",
  //               // },
  //               {
  //                 name: "Jenis Barang",
  //                 to: "/master/jenis_barang",
  //               },
  //               {
  //                 name: "Jenis Layanan",
  //                 to: "/master/jenis_layanan",
  //               },
  //               {
  //                 name: "Jenis Tenaga Medis",
  //                 to: "/master/jenis_tenaga_medis",
  //               },
  //               {
  //                 name: "Kelas",
  //                 to: "/master/kelas",
  //               },
  //               {
  //                 name: "Kelas Terapi",
  //                 to: "/master/kelas_terapi",
  //               },
  //               {
  //                 name: "Kelompok Pangan",
  //                 to: "/master/kelompok_pangan",
  //               },
  //               {
  //                 name: "Komponen Tarif",
  //                 to: "/master/komponen_tarif",
  //               },
  //               {
  //                 name: "Komposisi Barang",
  //                 to: "/master/komposisi_barang",
  //               },
  //               {
  //                 name: "Kualifikasi",
  //                 to: "/master/kualifikasi",
  //               },
  //               // {
  //               //   name: "Layanan",
  //               //   to: "/master/layanan",
  //               // },
  //               {
  //                 name: "Loket",
  //                 to: "/master/loket",
  //               },
  //               {
  //                 name: "MCU",
  //                 to: "/master/mcu",
  //               },
  //               {
  //                 name: "Pangan",
  //                 to: "/master/pangan",
  //               },
  //               {
  //                 name: "Produsen",
  //                 to: "/master/produsen",
  //               },
  //               {
  //                 name: "Satuan Barang",
  //                 to: "/master/satuan_barang",
  //               },
  //               {
  //                 name: "Specialist",
  //                 to: "/master/specialist",
  //               },
  //               {
  //                 name: "Status Pulang",
  //                 to: "/master/status_pulang",
  //               },
  //               {
  //                 name: "Sumber Dana",
  //                 to: "/master/sumber_dana",
  //               },
  //               {
  //                 name: "Pendidikan",
  //                 to: "/master/pendidikan",
  //               },
  //               {
  //                 name: "Poli",
  //                 to: "/master/poli",
  //               },
  //               {
  //                 name: "Puskesmas",
  //                 to: "/master/puskesmas",
  //               },
  //               {
  //                 name: "Tenaga Medis",
  //                 to: "/master/tenaga_medis",
  //               },
  //               {
  //                 name: "Tipe Pangan",
  //                 to: "/master/tipe_pangan",
  //               },
  //               {
  //                 name: "Tindakan",
  //                 to: "/master/tindakan",
  //               },
  //               {
  //                 name: "User",
  //                 to: "/master/user",
  //               },
  //             ],
  //           },
  //           {
  //             _name: "CSidebarNavDropdown",
  //             name: "Apotek",
  //             route: "/apotek",
  //             icon: "cil-medical-cross",
  //             items: [
  //               {
  //                 name: "Resep",
  //                 to: "/apotek/resep",
  //               },
  //             ],
  //           },
  //           {
  //             _name: "CSidebarNavDropdown",
  //             name: "Bed",
  //             route: "/bed",
  //             icon: "cil-bed",
  //             items: [
  //               {
  //                 name: "Master Bed",
  //                 to: "/bed/ms_bed",
  //               },
  //               {
  //                 name: "Ruang",
  //                 to: "/bed/ms_ruang",
  //               },
  //               {
  //                 name: "Kamar",
  //                 to: "/bed/ms_kamar",
  //               },
  //             ],
  //           },
  //           {
  //             _name: "CSidebarNavDropdown",
  //             name: "Antrian",
  //             route: "/antrian",
  //             icon: "cil-library",
  //             items: [
  //               {
  //                 name: "Antrian Loket",
  //                 to: "/antrian/antrian_loket",
  //               },
  //               {
  //                 name: "Antrian Poli",
  //                 to: "/antrian/antrian_poli",
  //               },
  //               {
  //                 name: "Antrian Farmasi",
  //                 to: "/antrian/antrian_farmasi",
  //               },
  //               {
  //                 name: "Display APM",
  //                 to: "/display_apm",
  //               },
  //               {
  //                 name: "Display Layar Poli",
  //                 to: "/display_list_poli",
  //               },
  //               {
  //                 name: "Display Layar Pendaftaran",
  //                 to: "/display_layar_loket",
  //               },
  //             ],
  //           },
  //           {
  //             _name: "CSidebarNavDropdown",
  //             name: "Pasien",
  //             route: "/pasien",
  //             icon: "cil-people",
  //             items: [
  //               {
  //                 name: "Rekam Medis",
  //                 to: "/pasien/rekam_medis",
  //               },
  //               {
  //                 name: "Pasien Meninggal",
  //                 to: "/pasien/pasien_meninggal",
  //               },
  //             ],
  //           },
  //           {
  //             _name: "CSidebarNavDropdown",
  //             name: "Registrasi",
  //             route: "/registrasi",
  //             icon: "cil-list",
  //             items: [
  //               {
  //                 name: "Pendaftaran",
  //                 to: "/registrasi/pendaftaran",
  //               },
  //               {
  //                 name: "Kunjungan",
  //                 to: "/registrasi/kunjungan",
  //               },
  //               {
  //                 name: "Rawat Inap",
  //                 to: "/registrasi/rawat_inap",
  //               },
  //             ],
  //           },
  //           {
  //             _name: "CSidebarNavDropdown",
  //             name: "Gudang",
  //             route: "/gudang",
  //             icon: "cil-layers",
  //             items: [
  //               {
  //                 name: "Stock",
  //                 to: "/gudang/stock/" + this.$store.state.puskesmas_id,
  //               },
  //               {
  //                 name: "Pembelian",
  //                 to: "/gudang/pembelian",
  //               },
  //               {
  //                 name: "Mutasi",
  //                 to: "/gudang/mutasi",
  //               },
  //             ],
  //           },
  //           {
  //             _name: "CSidebarNavDropdown",
  //             name: "Laboratorium",
  //             route: "/laboratorium",
  //             icon: "cil-layers",
  //             items: [
  //               {
  //                 name: "Satuan Laboratorium",
  //                 to: "/laboratorium/satuan_lab",
  //               },
  //               {
  //                 name: "Sampel Lab",
  //                 to: "/laboratorium/sampel_lab",
  //               },
  //               {
  //                 name: "Nilai Rujukan Lab",
  //                 to: "/laboratorium/nilai_rujukan_lab",
  //               },
  //               {
  //                 name: "Tindakan Lab",
  //                 to: "/laboratorium/tindakan_lab",
  //               },
  //               {
  //                 name: "Paket Lab",
  //                 to: "/laboratorium/paket_lab",
  //               },
  //               {
  //                 name: "Pemeriksaan Lab",
  //                 to: "/laboratorium/pemeriksaan_lab",
  //               },
  //             ],
  //           },
  //           {
  //             _name: "CSidebarNavDropdown",
  //             name: "Kasir",
  //             route: "/kasir",
  //             icon: "cil-layers",
  //             items: [
  //               {
  //                 name: "Pembayaran",
  //                 to: "/kasir/pembayaran",
  //               },
  //             ]
  //           },
  //         ],
  //       },
  //     ];
  //   },
  // },
  watch: {
    acc: function (newV, oldV) {
      if (newV != oldV) {
        // this.get_all_akses();
        // console.log(this.codedSidebarItems);
      }
    },
    computedUrl(newV, oldV) {
      if (newV != oldV) {
        this.$store.commit("set_akses_url", newV);
      }
    },
    // codedSidebarItems(val){
    //   console.log(val, this.acc)
    // }
  },
  computed: {
    roles() {
      return this.$store.state.user_role ? this.$store.state.user_role : null;
    },
    role_id() {
      return this.$store.state.role_id ? this.$store.state.role_id : null;
    },
    acc() {
      return this.$store.state.all_akses ? this.$store.state.all_akses : [];
    },
    codedSidebarItems() {
      return this.AllSideBarItem.map((item) => {
        // return item;
        for (let i = 0; i < this.ms_akses.length; i++) {
          if (item.route == this.ms_akses[i].url || item.to == this.ms_akses[i].url) {
            item.kode = this.ms_akses[i].kode_akses;
            if (item.items) {
              item.items = item.items.map((itemx) => {
                for (let k = 0; k < this.ms_akses.length; k++) {
                  if (itemx.to == this.ms_akses[k].url) {
                    itemx.kode = this.ms_akses[k].kode_akses;
                  }
                }
                return itemx;
              });
            }
          }
        }
        return item;
      });
    },
    computedUrl() {
      let x = [];
      if (this.ms_akses.length && this.acc.length) {
        // console.log(this.acc, 'ulrs')
        this.ms_akses.forEach((ele) => {
          if (ele.items) {
            ele.items.forEach((eles) => {
              if (this.acc.includes(eles.kode_akses)) {
                x.push(eles.url);
              }
            });
          }
          if (this.acc.includes(ele.kode_akses)) {
            x.push(ele.url);
          }
        });
      }
      return x;
    },
    computedSideBarItems() {
      return this.codedSidebarItems.filter((item) => {
        if (item.items) {
          item.items = item.items.filter((items) => {
            return !items.kode || this.acc.includes(items.kode);
          });
        }
        return !item.kode || this.acc.includes(item.kode);
      });
    },
    sidebarItems() {
      return [
        {
          _name: "CSidebarNav",
          _children: this.computedSideBarItems,
        },
      ];
    },
  },
  mounted() {
    this.$store.commit("set_akses_url", []);
  },
  created() {
    // this.get_all_akses();
    this.get_master_akses();
  },
  methods: {
    async get_master_akses() {
      let vm = this;
      let ms_akses = await vm.$axios.post("ms_akses/list", {});
      // console.log(ms_akses.data.data, "sidebar lho");
      vm.ms_akses = ms_akses.data.data
        ? ms_akses.data.data.map((item) => {
            return item;
          })
        : [];
    },
    async get_all_akses() {
      let vm = this;

      let ms_akses = await vm.$axios.post("ms_akses/list", {
        id: vm.role_id,
      });
      // console.log(ms_akses.data.data,'sidebar lho')
      vm.ms_akses = ms_akses.data.data
        ? ms_akses.data.data.map((item) => {
            return item;
          })
        : [];
    },
  },
};
</script>
